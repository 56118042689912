

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: white !important;
}
.user_card {
  height: 500px;
  width: 350px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(50%);
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background:whitesmoke;
  padding: 10px;
  text-align: center;
}
.brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid white;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #c0392b !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #c0392b !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #c0392b !important;
}

.Loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 9999;
}


#Animation {
  position: relative;
  animation-name: example;
  animation-duration: 3s;  
  animation-delay: 1s;
  animation-fill-mode: both;
}

@keyframes example {
  from {top: 0px;}
  to {top: 60px;}
}

.animated-text {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.expensive-text {
  font-size: 2rem;
  background-image: linear-gradient(to right, #ffbd00, #ff006c);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}



.premium {
  position: relative;
  max-width: 300px;
  height: auto;
  background-color: #A1A2A3;
  background-image: linear-gradient(315deg, #A1A2A3 0%, #BEC0C2 60%);
  border-radius: 15px;
  margin: 0 auto;
  padding: 40px 20px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  -webkit-transition: .5s;
  transition: .5s;
}

.Gold {
  position: relative;
  max-width: 300px;
  height: auto;
  background-color: #E5B80B;
  background-image: linear-gradient(315deg, #E5B80B 0%, #ffef77 60%);
  border-radius: 15px;
  margin: 0 auto;
  padding: 40px 20px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  -webkit-transition: .5s;
  transition: .5s;
}
.Basic{
  position: relative;
  max-width: 300px;
  height: auto;
  background: linear-gradient(-45deg, lightslategrey,lightblue);
  border-radius: 15px;
  margin: 0 auto;
  padding: 40px 20px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
  -webkit-transition: .5s;
  transition: .5s;
}
.vl {
  border-left: 2px solid white;
  height: 420px;
  margin-top: 7px;
}

.xcard {
  position: relative;
  animation-name: rocketAnimation;
  animation-duration: 3s;  
  animation-delay: 1s;
  animation-fill-mode: both;
}

@keyframes rocketAnimation {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(50px, 50px) rotate(180deg);
  }
  100% {
    transform: translate(100px, 0) rotate(360deg);
  }
}

.table-container {
  overflow-x: auto;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th, .data-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.data-table ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2;
}



.search-bar {
  margin-bottom: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* .popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.popup-content button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */
/* Default styles for the popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.popup-content-new {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.popup-content-new button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}
.popup-content button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .popup-content {
    width: 100%;
    padding: 10px; 
    overflow-y: auto;

  }


  
  .popup-content .d-flex.flex-row {
    flex-direction: column;
    
  }
  
  .popup-content .d-flex.flex-row .vl {
    display: none;
  }
  
  .popup-content .d-flex.flex-row .p-2.w-50 {
    width: 100%; 
  }
  
  .popup-content span {
    font-size: 14px;
  }
  
  .popup-content .form-outline {
    margin-bottom: 10px; 
  }
  
  .popup-content .form-control.bg-light {
    width: 100%;
  }
  .popup-content .d-flex.flex-row {
    width: 200%;
}
}

@media (min-width: 768px) {
  .popup-content {
    /* width: 80%; */
    padding: 20px; 
    overflow-y: auto;
height: 100%;
  }
  }


.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px;
}

.pagination li {
  display: inline;
}

.pagination li a {
  color: #007BFF;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination li.active a {
  background-color: #007BFF;
  color: #fff;
}

.pagination li a:hover {
  background-color: #ddd;
}
.average-ranking {
  text-align: center;
  margin-top: -14px;
  font-size: 16px;
  font-weight: bold;
  color: #007BFF;
}

.average-ranking h6 {
  margin: 0;
  padding: 0;
}
    

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  margin-right: 10px;
}

.pagination li a {
  text-decoration: none;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
}

.pagination li.active a {
  background-color: #0056b3;
  color: white;
}

.pagination li a:hover {
  background-color: #0056b3;
  color: white;
}

.pagination .break {
  padding: 0 5px;
}


.anecdotes-input {
 
  padding: 8px; 
  font-size: 14px; 
  border: 1px solid #ccc;
  border-radius: 4px; 
}


.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.search-bar i {
  margin-right: 10px; 
  font-size: 18px; 
  color: #555;
}

.search-bar input[type="text"] {
  flex: 1; 
  padding: 10px;
  border: none;
  outline: none;
  font-size: 16px;
}


*{
  margin: 0;
  padding: 0;
}
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position:absolute;
  top:-9999px;
}
.rate:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:30px;
  color:#ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}



.pagination-container {
  margin-top: 10px; 
}

#itemsPerPage {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination li.active a {
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
}

.pagination li a {
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #007bff;
  border-radius: 4px;
  color: #007bff;
  cursor: pointer;
}

.pagination li a:hover {
  background-color: #007bff;
  color: #fff;
}



.container {
  animation: fadeIn 1s ease-in-out; 
}

.registration-container {
  margin-bottom: 5rem;
  width: 100%;
  animation: slideUp 1s ease-in-out; 
}

.card-header {
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.form-group {
  animation: fadeIn 1s ease-in-out; 
}

.text-center {
  animation: fadeIn 1s ease-in-out; 
}

.text-danger {
  color: red;
  animation: fadeIn 1s ease-in-out; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}



.star-rating label {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
}

.star {
  font-size: 20px; 
  color: #f7d93d; 
}

.star:hover {
  color: #f7d93d;
}


.radio-star{
  display: none;

}
.radio-star:checked + .star {
  color: #f7d93d; 

}

.table-container {
  /* max-height: 500px;  */
  overflow-y: scroll; 
}
th {
  background-color: #A1A2A3;
  position: sticky; 
  top: 0; 
  z-index: 1;
}



  





@media only screen and (min-device-width: 768px) and (max-device-width: 1233px) {
  .wrap {
    margin-left: -30px !important;
  }
}






.wrap{
  background-color: #fff;
  margin: 0;
  padding: 0;
  
}
.label-radio{
  position: relative;
  display: inline-block;
  text-align: center;
}


.radio-button-y-n-n:hover {
  cursor: pointer !important;
}

.radio-button-y-n-n:active {
  box-shadow: 0 1px #666 !important;
  transform: translateY(2px) !important;
}
.radio-button-y-n-n{
  
  font-size: 15px;
  font-weight: bold;
  height: 35px;
  width: 100px;
  box-shadow: 0 3px #999;
  text-align: center;
}
.radio-button-y-n-n{
  transition: all 0.2s ease;
  background-color: white ;
  border: 2px solid #f44336 !important;
  box-shadow: 0 3px #d32f2f !important;
  min-width: 50px;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .wrap {
    text-align: center;
  }
  .centered-content {
    display: inline-block;
  }
}

  .radio-button-y-n-n::selection{
      background: green;
  }

.radio-input-c-n-n{
   position: absolute;
visibility: hidden;
}
.radio-input-c-n-n + div {
  position: relative;
}
.radio-input-c-n-n:checked + div {
background-color: #ef5350;
}
.radio-input-c-n-n:checked + div>span {
color: white;
}
.radio-input-c-n-n+ div>span {
position: relative;
top: 0%;}

.radio-input-c-n-n {
   position: absolute;
visibility: hidden;
}

.radio-input-c-n-n + div {
  position: relative;
}
.radio-input-c-n-n:checked + div {
background-color: #ef5350;
}
.radio-input-c-n-n:checked + div>span {
color: white;
}
.radio-input-c-n-n + div>span {
position: relative;
top: 25%;}

.radio-input-c-n-n:checked + div::before {
      content:"✔";
  position: absolute;
  bottom: 18px;
  right: 0px;
  font-size: 21px;
  color: white;
}

.radio-input-c-n-n:checked + div::before {
  font-family: FontAwesome;
  content: "\f08d";
  position: absolute;
  bottom: 31px;
  font-size: 21px;
  color: #666;
  right: -5px;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  animation: fall 0.5s forwards;
}

@keyframes fall {
  100% {
      -webkit-transform: translate(-5px,5px) rotate(30deg);
      -moz-transform: translate(-5px,5px) rotate(30deg);
      -o-transform: translate(-5px,5px) rotate(30deg);
      -ms-transform: translate(-5px,5px) rotate(30deg);
      transform: translate(-5px,5px) rotate(30deg);
  }
}

@-moz-keyframes fall {
  100% {
      -webkit-transform: translate(-5px,5px) rotate(30deg);
      -moz-transform: translate(-5px,5px) rotate(30deg);
      -o-transform: translate(-5px,5px) rotate(30deg);
      -ms-transform: translate(-5px,5px) rotate(30deg);
      transform: translate(-5px,5px) rotate(30deg);
  }
}

@-webkit-keyframes fall {
   100% {
      -webkit-transform: translate(-5px,5px) rotate(30deg);
      -moz-transform: translate(-5px,5px) rotate(30deg);
      -o-transform: translate(-5px,5px) rotate(30deg);
      -ms-transform: translate(-5px,5px) rotate(30deg);
      transform: translate(-5px,5px) rotate(30deg);
  }
}

.radio-star:hover ~ i.fas.fa-star {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}




.center-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 80px;
  background-color:white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.support-button-container {
  text-align: center;
}
.support-button-secound{
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  width: 500px;
}
.support-button {
  padding: 10px 20px;
  font-size: 16px;
  background: #004570;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  width: 500px;
}

.support-button:hover {
  background-color: #0056b3;
}






@media only screen and (max-width: 600px) {
  .popup-content {
    height:90%;
    overflow-y: auto;

    }

    .center-body{
      margin: 15px;
    }

    .support-button-secound{
      width: 250px;
    }

    .support-button{
      width: 250px;
    }

}

.mobile-only {
  display: none;
}

@media (max-width: 990px) {
  .mobile-only {
    display: inline; 
  }
}



.select-with-icon {
  position: relative;
}

.select-with-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
  pointer-events: none;
}


.responsive-text {
  font-size: 18px;
}

.responsive-text-options{
  font-size: 15px;

}

@media (max-width: 768px) {
  .responsive-text {
    font-size: 16px;
  }
  .responsive-text-options{
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .responsive-text {
    font-size: 14px;
  }
  .responsive-text-options{
    font-size: 16px;
  }
}

.no-data-available {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  background-color: #f8d7da; 
  border: 1px solid #f5c6cb;
  color: #721c24;
  border-radius: 4px;
}


.highlighted-row{
  color: #721c24;
  background-color: #721c24;
}

.search-container {
  padding: 3px;
  color: #004570;
  border-color: #007bff;;
  border-radius: 10px;
}

.search-container:hover {
  border-color: #004570;
}

@media only screen and (max-width: 768px) {
  .search-container {
    width: 102%;
  }
}




.form-input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 10px;
  display: block;
  text-align: center;
  font-size: 20px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  height: 13px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  z-index: 9;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 4s ease-in-out infinite;
  -moz-animation:    fade_move_down 4s ease-in-out infinite;
  animation:         fade_move_down 4s ease-in-out infinite;
}


/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,10px) rotate(45deg); opacity: 0; }
}




.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}



.delivery-section {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.delivery-section label {
  font-size: 18px;
  font-weight: bold;
}

.delivery-options {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.form-check-inline {
  margin-right: 20px;
}

.manual-score input[type="number"] {
  width: 150px;
  margin-right: 10px;
}

.delivery-score {
  margin-top: 15px;
  font-weight: bold;
  color: #007bff;
}
